<template>
  <div class="accordian">
    <div class="accordian-title" @click.stop="$emit('open', linkGroup.id)">
      <p class="title-1 title">
        {{ linkGroup.title }}
      </p>
      <svg class="lb-icon no-fill stroke angle-icon" :class="isOpen ? 'up': 'down'">
        <use xlink:href="#dropdown" />
      </svg>
    </div>
    <div v-show="isOpen" class="accordian-body">
      <ul class="group-links">
        <li v-for="item in linkGroup.links" :key="item._uid">
          <template v-if="item.component === FooterItemType.Link">
            <a v-if="item.external" class="paragraph-1" :href="item.href" target="_blank">
              {{ item.title }}
            </a>
            <router-link v-else class="paragraph-1" :to="item.href">
              {{ item.title }}
            </router-link>
          </template>
          <template v-else>
            <div class="group-heading" v-if="item.isTitle">
              <div class="group-heading-title">
                <p class="title-1">
                  {{ item.title }}
                </p>
              </div>
            </div>
            <p class="paragraph-1" v-else>
              {{ item.title }}
            </p>
          </template>
        </li>
      </ul>
      <div class="additional-info">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import { FooterItemType } from 'src/modules/storyblok/store/types';

export default {
  props: {
    linkGroup: {
      type: Object,
      default: () => ({})
    },
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    FooterItemType () {
      return FooterItemType;
    }
  }
}
</script>

<style lang="scss" scoped>
.accordian {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid var(--neutral-100);
  .accordian-title {
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: space-between;
    .angle-icon {
      width: 18px;
      height: 18px;

      &.up {
        transform: rotate(180deg);
      }
    }
    .title {
      padding: var(--spacing-xl, 16px) 0;
    }
  }
  .accordian-body {
    padding: 0 5px 20px 5px;
    .group-links {
      gap: 16px;
      display: flex;
      flex-direction: column;

      .group-heading {
        display: flex;
        flex-direction: column;
        border-bottom: none;
        border-top: 1px rgba(65, 64, 66, 0.5) solid;
        &-title {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
    }

    .additional-info {
      margin-top: 5px;
    }
  }
}
</style>
